import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import styled from '@emotion/styled';
import useContentfulImage from '../utils/useContentfulImage';
import { Seo } from '../components/Seo';
import Layout from '../components/Layout';
import { MEDIA_QUERY, COLOR, OPACITY } from '../styles/variables';
import { ArticleList } from '../components/ArticleList';
import { CommonBlock } from '../components/CommonBlock';
import { CommonContent } from '../components/CommonContent';
import { CategoryList } from '../components/CategoryList';
import { SnsShare } from '../components/SnsShare';
import { PrismComponent } from '../components/PrismComponent';
import 'prismjs/themes/prism.css';
// import { Ad_GoogleSquareDouble } from '../ad/AdGoogleSquareDouble';

const options = {
  renderMark: {
    [MARKS.CODE]: (text) => {
      // 1行目に記述したタイトルを取得する
      const title = text[1];

      // 2行目に記述した言語名をclass名に付与する
      const langname = text[3];

      // 中身を取り出す
      const textContent = text.slice(4, text.length);

      // 空白を改行にする
      for (let i = 1; i < textContent.length; i++) {
        if (i % 2 === 0) {
          textContent[i] = '\n';
        }
      }

      return (
        <div>
          <CodeTitle>{title}</CodeTitle>
          <PrismComponent langname={langname}>{textContent}</PrismComponent>
        </div>
      );
    },
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node: any, children: React.ReactNode): JSX.Element => (
      <div>{children}</div>
    ),
    [BLOCKS.HEADING_2]: (node: any, children: React.ReactNode): JSX.Element => (
      <h2>{children}</h2>
    ),
    [BLOCKS.HEADING_3]: (node: any, children: React.ReactNode): JSX.Element => (
      <h3>{children}</h3>
    ),
    [BLOCKS.LIST_ITEM]: (node: any, children: React.ReactNode): JSX.Element => (
      <li>
        <div>{children}</div>
      </li>
    ),
    [BLOCKS.EMBEDDED_ASSET]: (node): JSX.Element => (
      <Img
        fluid={useContentfulImage(node.data.target.fields.file['ja-JP'].url)}
        alt={
          node.data.target.fields.description
            ? node.data.target.fields.description['ja-JP']
            : node.data.target.fields.title['ja-JP']
        }
      />
    ),
  },
  renderText: (text): JSX.Element => {
    return text.split('\n').reduce((children, textSegment, index) => {
      return [...children, index > 0 && <br key={index} />, textSegment];
    }, []);
  },
};

export default ({ data, location }): JSX.Element => (
  <Layout>
    <Seo
      pagetitle={data.contentfulBlogPost.title}
      pagedesc={
        data.contentfulBlogPost.description
          ? data.contentfulBlogPost.description
          : `${documentToPlainTextString(
              data.contentfulBlogPost.content.json,
            ).slice(0, 70)}…`
      }
      pagepath={location.pathname}
      blogimg={`https:${data.contentfulBlogPost.eyecatch.file.url}`}
      pageimgw={data.contentfulBlogPost.eyecatch.file.details.image.width}
      pageimgh={data.contentfulBlogPost.eyecatch.file.details.image.height}
      requireJsonLd
      publishDate={data.contentfulBlogPost.publishDate}
      updatedAt={data.contentfulBlogPost.updatedAt}
    />
    <CommonContent>
      <CommonBlock>
        <Title>{data.contentfulBlogPost.title}</Title>
        <Info>
          <Date dateTime={data.contentfulBlogPost.publishDate}>
            <FontAwesomeIcon icon={faClock} />
            {data.contentfulBlogPost.publishDateJP}
          </Date>
          <CategoryWrapper>
            <CategoryList data={data.contentfulBlogPost.category} />
          </CategoryWrapper>
        </Info>
        <Eyecatch>
          <EyecatchFigure>
            <Img
              fluid={data.contentfulBlogPost.eyecatch.fluid}
              alt={data.contentfulBlogPost.eyecatch.description}
            />
          </EyecatchFigure>
        </Eyecatch>
        <PostBody>
          {documentToReactComponents(
            data.contentfulBlogPost.content.json,
            options,
          )}
        </PostBody>
        <SnsShare
          pageTitle={data.contentfulBlogPost.title}
          pagePath={location.pathname}
        />
      </CommonBlock>

      {/* <Ad_GoogleSquareDouble /> */}

      <ArticleBlockWrapper>
        <CommonBlock title="Recent Article">
          <ArticleList data={data} />
        </CommonBlock>
      </ArticleBlockWrapper>
    </CommonContent>
  </Layout>
);

export const query = graphql`
  query($id: String!) {
    contentfulBlogPost(id: { eq: $id }) {
      title
      publishDateJP: publishDate(formatString: "YYYY年MM月DD日")
      publishDate
      updatedAt
      category {
        category
        categorySlug
        id
      }
      description
      eyecatch {
        fluid(maxWidth: 900, maxHeight: 480, quality: 80) {
          ...GatsbyContentfulFluid_withWebp
        }
        description
        file {
          details {
            image {
              width
              height
            }
          }
          url
        }
      }
      content {
        json
      }
    }
    allContentfulBlogPost(
      sort: { fields: publishDate, order: DESC }
      limit: 8
      skip: 0
    ) {
      edges {
        node {
          title
          slug
          id
          eyecatch {
            description
            fluid(maxWidth: 300) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`;

const PostBody = styled.div`
  padding-bottom: 40px;
  font-size: 1.5rem;

  > * {
    margin-top: 28px;
  }

  h2,
  h3 {
    line-height: 1.3;
    text-align: left;
  }

  h2 {
    margin-top: 1.5em;
    font-size: 2.8rem;
    padding-bottom: 4px;
    border-bottom: solid 1px ${COLOR.article.titleUnderLine};

    @media (min-width: ${MEDIA_QUERY.middle}) {
      font-size: 3.8rem;
    }
  }

  h3 {
    font-size: 2rem;

    @media (min-width: ${MEDIA_QUERY.middle}) {
      font-size: 2.4rem;
    }
  }

  div,
  p,
  ul {
    line-height: 1.8;
    font-size: 1.6rem;

    + h2 {
      margin-top: 80px;
    }

    + h3 {
      margin-top: 60px;
    }
  }

  a {
    color: ${COLOR.article.linkText};
    text-decoration: underline;

    &:hover {
      opacity: ${OPACITY.linkHover};
    }
  }

  hr {
    height: 1px;
    margin: 60px 0;
    border: none;
    background-color: ${COLOR.article.hr};
  }

  li {
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 12px;
    padding-left: 16px;

    div {
      flex: 1;
      line-height: 1.6;
    }

    &::before {
      display: block;
      width: 1em;
      text-align: center;
      content: '';
      width: 6px;
      height: 6px;
      background-color: ${COLOR.article.listDisc};
      border-radius: 100%;
      position: absolute;
      left: 0;
      top: calc(1.6rem * 1.6 / 2 - 3px);
    }
  }

  i {
    margin: -1px 4px;
    padding: 2px 4px;
    border-radius: 4px;
    font-size: 1.4rem;
    border: solid 1px ${COLOR.article.inlineCodeBorder};
    background-color: ${COLOR.article.inlineCodeBg};
    font-style: normal;
  }

  u {
    text-decoration: none;
    background: linear-gradient(transparent 75%, #c6e1fc 75%);
    font-weight: bold;
  }

  pre {
    margin: 0;
    border-radius: 0 4px 4px 4px;
  }

  code {
    font-size: 1.4rem;
    /* 折り返して表示する場合↓ */
    /* white-space: pre-wrap; */
  }
`;

const Title = styled.h2`
  font-size: 2.4rem;
  text-align: left;

  @media (min-width: ${MEDIA_QUERY.middle}) {
    font-size: 3.8rem;
  }
`;

const Info = styled.aside`
  margin-top: 32px;
  color: ${COLOR.text.sub};
  font-size: 1.4rem;

  [class*='fa-'] {
    margin-right: 10px;
  }

  @media (min-width: ${MEDIA_QUERY.middle}) {
    display: flex;
    justify-content: space-between;
    font-size: 1.6rem;
  }
`;

const Date = styled.time`
  margin-top: 8px;
`;

const CategoryWrapper = styled.div`
  margin-top: 8px;
`;

const ArticleBlockWrapper = styled.div`
  margin-top: 40px;
`;

const Eyecatch = styled.div`
  margin-top: 32px;
`;

const EyecatchFigure = styled.figure`
  margin-top: 32px;
`;

const CodeTitle = styled.span`
  display: inline-block;
  padding: 4px 8px;
  color: ${COLOR.article.codeTitleColor};
  background: ${COLOR.article.codeTitleBg};
  border-radius: 4px 4px 0 0;
  font-size: 1.2rem;
  line-height: 1.3;
`;
