import React from 'react';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faFacebook } from '@fortawesome/free-brands-svg-icons';
import { COLOR } from '../styles/variables';

type Props = {
  pageTitle: string;
  pagePath: string;
};

const SnsShare = (props: Props): JSX.Element => {
  const { pageTitle, pagePath } = props;

  const twitterShareUrl = `https://twitter.com/intent/tweet?text=${pageTitle}&url=https://fe-designer.com${pagePath}&hashtags=fedesigner`;
  const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=https://fe-designer.com${pagePath}`;

  return (
    <DIV_Wrap>
      <P_Please>この記事をシェアしてくれると小躍りします</P_Please>
      <Div_ShareWrapper>
        <A_Share
          aria-label="Twitterでシェア"
          href={twitterShareUrl}
          target="_blank"
          rel="nofollow noopener noreferrer"
          colorCode={COLOR.sns.twitter}
        >
          <FontAwesomeIcon icon={faTwitter} />
        </A_Share>
        <A_Share
          aria-label="Facebookでシェア"
          href={facebookShareUrl}
          target="_blank"
          rel="nofollow noopener noreferrer"
          colorCode={COLOR.sns.facebook}
        >
          <FontAwesomeIcon icon={faFacebook} />
        </A_Share>
      </Div_ShareWrapper>
    </DIV_Wrap>
  );
};

const DIV_Wrap = styled.div`
  margin-top: 40px;
  padding-top: 40px;
  border-top: solid 1px ${COLOR.article.hr};
  text-align: center;
`;

const P_Please = styled.p`
  text-align: center;
  font-size: 1.4rem;
  color: ${COLOR.text.sub};
`;

const Div_ShareWrapper = styled.div`
  margin-top: 20px;
`;

const A_Share = styled.a<{ colorCode: string }>`
  margin: 0 32px;
  font-size: 5rem;
  ${({ colorCode }): string => `color: ${colorCode}`}; ;
`;

export { SnsShare };
